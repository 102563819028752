// If you comment out code below, bootstrap will use red as primary color
// and btn-primary will become red

// $primary: red;

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
    font-family: MonteCarlo;
    src: url('./fonts/MonteCarlo-Regular.woff2') format('woff2'),
        url('./fonts/MonteCarlo-Regular.woff') format('woff'),
        url('./fonts/MonteCarlo-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    src: url('./fonts/LibreBaskerville-Regular.woff2') format('woff2'),
        url('./fonts/LibreBaskerville-Regular.woff') format('woff'),
        url('./fonts/LibreBaskerville-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    src: url('./fonts/LibreBaskerville-Bold.woff2') format('woff2'),
        url('./fonts/LibreBaskerville-Bold.woff') format('woff'),
        url('./fonts/LibreBaskerville-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Franklin';
    src: url('./fonts/LibreFranklin-Bold.woff2') format('woff2'),
        url('./fonts/LibreFranklin-Bold.woff') format('woff'),
        url('./fonts/LibreFranklin-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Franklin';
    src: url('./fonts/LibreFranklin-Light.woff2') format('woff2'),
        url('./fonts/LibreFranklin-Light.woff') format('woff'),
        url('./fonts/LibreFranklin-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Franklin';
    src: url('./fonts/LibreFranklin-Regular.woff2') format('woff2'),
        url('./fonts/LibreFranklin-Regular.woff') format('woff'),
        url('./fonts/LibreFranklin-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Franklin';
    src: url('./fonts/LibreFranklin-SemiBold.woff2') format('woff2'),
        url('./fonts/LibreFranklin-SemiBold.woff') format('woff'),
        url('./fonts/LibreFranklin-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

h1 {
    color: #111;
    font-family: 'Libre Franklin';
    @apply mb-3;
    @apply text-[40px] font-light leading-tight md:text-[42px];
}

h2 {
    color: #111;
    font-family: 'Libre Franklin';

    @apply mb-3;

    @apply text-[23px] font-light leading-tight md:text-[32px];
}

h3 {
    color: #111;
    font-family: 'Libre Franklin';

    @apply mb-3;

    @apply text-[14px] md:text-[14px] lg:text-[14px];
}

h4 {
    color: #111;
    font-family: 'Libre Franklin';

    @apply mb-3;

    @apply text-[12px] md:text-[12px] lg:text-[12px];
}

h5 {
    color: #111;
    font-family: 'Libre Franklin';

    @apply mb-3;

    @apply text-[16px] font-light lg:text-[18px];
}

p {
    font-family: 'Libre Baskerville';

    @apply mb-4;
    @apply text-[14px] leading-6;
}

ul {
    @apply pl-8 pb-4;
}

li {
    font-family: 'Libre Baskerville';
    list-style-type: disc;
    @apply text-[14px] leading-6 pb-3 pl-2;
}

li::marker {
    color: #a89996;
    font-size: 20px;
}

a {
    @apply text-[16px];
}

html {
    scroll-behavior: smooth;
}

body {
    color: #212121;
    font-family: 'Libre Franklin';
    background-color: #f5f0f0;
}

.layoutVertical {
    @apply flex gap-4 md:gap-8 lg:gap-16  items-center min-h-[900px] md:pt-[25%] lg:pt-0  flex-col-reverse justify-evenly sm:flex-row sm:justify-between pt-[130px];
}

.layout {
    @apply max-w-screen-2xl mx-auto;
    @apply pl-6 pr-6 md:pl-[10%] md:pr-[10%];
}

.layoutFooter {
    @apply max-w-screen-2xl mx-auto;
    @apply pl-6 pr-6 md:pl-[10%] md:pr-[10%];

    @apply pt-16 pb-16;
}

.imageMarginRight {
    @apply mr-8;
}

.buttonMain {
    background-color: #e0ccc8;
    border-radius: 20px;
    @apply w-fit p-2 pl-5 pr-5 font-semibold cursor-pointer text-center;
}

.button {
    background-color: #a89996;
    border-radius: 20px;
    @apply w-full text-center sm:w-fit p-2 pl-5 pr-5 font-semibold cursor-pointer;

    padding-right: 40px;
    background-image: url('../../vendors/images/btnRight.svg');
    background-repeat: no-repeat;
    background-size: contain 80%;
    background-position: 85% 50%;
}

.button:hover {
    background-color: #a89996;
    color: white;
    transition: 1s ease;
    @apply sm:pr-[40px];
}

.dropdown {
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: pointer;

    padding-right: 1.5vw;
    background-image: url('../../vendors/images/arrowDown.svg');
    background-repeat: no-repeat;
    background-size: contain 80%;
    background-position: 100% 50%;
}

.dropdown:hover {
    background-image: url('../../vendors/images/arrowUp.svg');
}

.dropdown-content,
.submenu {
    transform: translateX(-20%);
    padding: 40px;
    display: none;
    position: absolute;
    background-color: white;

    min-height: 30px;
    z-index: 1;
    border-radius: 10px;
}

.dropdown-content a,
.dropdown-content > div {
    color: black;
    min-height: 48px;
    border-radius: 10px;
    text-decoration: none;
    @apply flex flex-col justify-center whitespace-nowrap;
}

.dropdown-content a {
    display: block;

    min-width: 220px;
}

// .dropdown .dropdown-content
.dropdown:hover .dropdown-content {
    @apply flex items-start justify-between gap-6;
    margin-top: 10px;
}

.dropdown:hover .dropbtn {
    font-weight: bold;
}

.dropdown:hover .iconSelected {
    display: block;
}

.dropdown:hover .iconNoraml {
    display: none;
}

.floatBtn {
}

.floatBtn:hover {
}

.baskerville {
    font-family: 'Libre Baskerville';
}

.italicBaskerville {
    font-family: 'Libre Baskerville';
    font-style: italic;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    font-size: 18px;
    background: transparent;
    transition: opacity 300ms;
}

.swiper-button-next,
.swiper-button-prev {
    background-color: white;
    background-color: #e0ccc8;
    right: 10px;
    padding: 30px;
    border-radius: 50%;
    color: white;
    font-size: 10px;
}

.swiper-button-prev::after,
.swiper-button-next::after {
    text-align: center;
    font-size: 20px;
}

.swiper-pagination-bullet {
    background-color: #798289;
}

.swiper-pagination {
    @apply mb-[5%];
}

.arrowLinks {
    a:not(.noLinkStyling) {
        font-family: 'Libre franklin';
        background-image: url('../../vendors/images/arrowRight.svg');
        background-repeat: no-repeat;
        background-size: contain 80%;
        background-position: 100% 50%;

        padding-right: 1em;

        color: #716a61;
        @apply text-[12px] md:text-[16px] font-bold uppercase;
    }
}

.arrowLinksBlack {
    a:not(.noLinkStyling) {
        font-family: 'Libre franklin';
        background-image: url('../../vendors/images/arrowRight.svg');
        background-repeat: no-repeat;
        background-size: contain 80%;
        background-position: 100% 50%;

        padding-right: 1em;

        color: #212121;
        @apply text-[12px] md:text-[16px] font-bold uppercase;
    }
}

.underlineLinks {
    a {
        font-family: 'Libre franklin';
        color: #716a61;
        @apply text-[14px] font-bold underline cursor-pointer;
    }
}

.linksInheritSize {
    a {
        font-size: inherit;
    }
}

.firstSemi:first-child {
    @apply font-semibold;
}

.secondMedium:nth-child(2) {
    @apply font-medium;
}

// cookies
.a101cc-cookie-popup {
    padding: 20px;
    background-color: #f9f5f4 !important;

    @media screen and (width >= 768px) {
        padding: 40px;
    }

    p {
        font-family: 'Libre Franklin';
    }

    a {
        font-size: inherit;
    }
}

.a101cc-banner-btn,
.a101cc-save-prefs-btn {
    font-family: 'Libre Franklin';
    background-color: #e0ccc8;
    border-radius: 20px;
    width: fit-content;
    cursor: pointer;
    padding: 0.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    text-align: center;
    font-weight: 600;
}

.a101cc-manage-btn {
    background: transparent;
}

.a101cc-manage-modal {
    h3 {
        font-size: 1.75rem;
        font-family: 'Libre Franklin';
    }

    h4 {
        font-size: 1.4rem;
        font-family: 'Libre Franklin';
    }

    p {
        font-size: 1rem;
        font-family: 'Libre Franklin';
    }

    input {
        accent-color: #e0ccc8;
    }
}
