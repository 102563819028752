#mainMenu {
    position: fixed;
    z-index: 1000;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    @apply bg-white top-0 overflow-hidden left-0 z-50 pl-8 pt-8 pr-8;
}

.nav-link-item {
    @apply text-[15px] font-semibold text-[#383332];
}

.dropdown-content .nav-link-item {
    @apply font-normal;
}

.menuContent > div > div {
    // border-bottom: 1px solid #DBCDBE;
}

.dropdown-content-MOBILE > div > div {
    // border-bottom: 1px solid #DBCDBE;
}

.dropdown-content-MOBILE > a {
    // border-bottom: 1px solid #DBCDBE;
}

.submenu-MOBILE > a {
    // border-bottom: 1px solid #DBCDBE;
}

.dropdown-MOBILE {
    .topLink,
    .secondaryLink {
        background-image: url('../../../vendors/images/arrowDown.svg');
        background-repeat: no-repeat;
        background-size: contain 80%;
        background-position: 100% 50%;
        @apply pr-4 cursor-pointer;
    }
}

.opened {
    .topLink {
        background-image: url('../../../vendors/images/arrowUp.svg');
    }
    .secondaryLink {
        background-image: url('../../../vendors/images/arrowUp.svg');
    }
}

.dropdown-MOBILE:hover .dropbtn {
    font-weight: bold;
    background-color: #f5f0f0;
}

.dropdown-MOBILE:hover .iconSelected {
    // display: block;
}

.dropdown-MOBILE:hover .iconNoraml {
    display: none;
}

.submenu-trigger-MOBILE:hover .iconSelectedSUB {
    // display: block;
}

.submenu-trigger-MOBILE:hover .iconNoramlSUB {
    display: none;
    @apply pt-0;
}

.dropdown-content-MOBILE {
    // CHANGE THIS TO HIDE
    // display: none;
}

.dropdown-MOBILE:hover .dropdown-content-MOBILE {
    // display: flex;
    border-bottom: none;
}
.submenu-MOBILE {
    // CHANGE THIS TO HIDE
    // display: none;
}

.submenu-trigger-MOBILE:hover .secondaryTrigger {
    background-color: #f5f0f0;
}

.submenu-trigger-MOBILE:hover .submenu-MOBILE {
    // display: flex;
    border-bottom: none;
    padding-top: 0px;
}

.secondaryTrigger:hover {
    background-color: #dbcdbe;
    font-weight: bold;
}

.menuItem:hover {
    background-color: #dbcdbe;
    font-weight: bold;
}
