
// HOME LOOPED SECTIONS CSS -----------------------------
.sectionLayout{
    @apply pt-12 pb-12 md:pt-32 md:pb-32; // VERTICAL
    @apply md:pl-12 md:pr-12;  // HORIZONTAL
}

.section__overlay{
    @apply hidden;
}
       
.section__content{

}  



.section__content__left{
    @apply md:w-1/2;

}
           
.section__content__right{
    @apply md:w-1/2;

}


.section--IMAGE--LEFT
{
    background-image: url('../../../vendors/images/s2BG.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    .section__content{
        
    }  
    .section__content__left{
    
    }   

}

.section--IMAGE--RIGHT
{
    background-image: url('../../../vendors/images/s1BG.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    .section__content{
        
    }  
    .section__content__left{
    
    }   
}




        

        
    
