.procedureInfoTextCss {
    @apply font-semibold uppercase mt-4 w-[90%];
}

.pageCenteredText h4,
.posegContent h4,
.lastWordText h4 {
    @apply uppercase;
    font-family: 'Libre Baskerville';
    color: #a89996;
}

.pageCenteredText h3,
.posegContent h3,
.lastWordText h3 {
    @apply font-bold uppercase;
    font-family: 'Libre Baskerville';
    color: #a89996;
}

.banner {
    h1 {
        @apply text-[17px];
    }
}

.readMore {
    a {
        font-family: 'Libre franklin';
        background-image: url('../../../vendors/images/arrowRight.svg');
        background-repeat: no-repeat;
        background-size: contain 80%;
        background-position: 100% 50%;
        padding-right: 1em;

        color: #383332;
        @apply text-[12px] md:text-[14px] font-bold uppercase;
    }
}

.readMoreImgRight {
    @apply left-[0%] md:left-[50%];
}

.readMoreImgLeft {
    @apply left-[0%];
}

.text-container {
    background-image: linear-gradient(
        to bottom,
        black 50%,
        transparent 100%
    ) !important;
    background-clip: text !important;
    color: transparent !important;
    -webkit-text-fill-color: transparent !important;

    /* Set a fixed height, or adjust as needed */
    overflow: hidden;
    height: 400px !important;
}

.clearfix::after {
    content: '';
    display: table;
    clear: both;
}

.headingsNormal {
    h3 {
        color: #a89996; /* Example: red color */
        -webkit-text-fill-color: #a89996; /* For Safari */
        background-clip: initial;
        -webkit-background-clip: initial;
    }
}

.content-container {
    img {
        @apply pr-6 pl-6 md:pl-0  md:pr-8;
    }
}

/*
.content-container:hover .readMore {
    display: none;
}

.content-container:hover .text-container {
    color: initial;
    -webkit-text-fill-color: #212121;
    overflow: visible;
    width: 100%;
    height: 100%;

    ul {
        list-style-type: disc;
    }
}
*/

.pageCenteredText {
    @apply text-center flex flex-col gap-1 items-center;
}

.lastWordText {
    @apply flex flex-col gap-1 items-center;
}

.bgSectionLight {
    background-color: #f9f5f4;
}

.bgSectionDark {
    background-color: #f5f0f0;
}

.procedureInclude {
    p {
        @apply uppercase;
    }
}

.overflowAuto {
    overflow: auto;
}
