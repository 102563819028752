.inputText {

    @apply p-3;

    border-radius: 6px;
    color: gray;

    @apply text-[17px];
}

.inputText:focus {
    color: black;
    outline: none;
    border: 2px solid #E0CCC8;
  
    border-color: #E0CCC8;
}


